<!--
 * @Author: your name
 * @Date: 2021-04-01 10:25:36
 * @LastEditTime: 2021-04-21 13:49:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\res\Tbcp\TbcpIndex.vue
-->
<template>
  <div class='TbcpIndex' :class="{showNav:$route.query.hidenav!='true'&&$route.query.type!='Ztxx'&&$route.path != '/Res/Ztxx'}">
      <series-list></series-list>
  </div>
</template>
<script>
import SeriesList from '../SunSpecialRes/Index'
export default {
  name: 'TbcpIndex',
  props:[],
  components: {SeriesList},
  data () {
    return {

    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {

  }
}
</script>

<style lang='less' scoped>
.showNav{
  padding-left: 95px;
}
</style>
