<template>
    <div class='TskcIndex'>
        123123
    </div>
</template>
<script>

export default {
    name: 'TskcIndex',
    props:[],
    components:{},
    data(){
        return {

        }
    },
    computed:{},
    watch:{},
    created(){},
    mounted(){},
    methods:{}
}
</script>

<style lang='less' scoped>

</style>
